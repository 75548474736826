module.exports = [{
      plugin: require('/var/fleet_tracker/app/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Montserrat","Space Mono"]}},
    },{
      plugin: require('/var/fleet_tracker/app/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-140859851-2","head":false},
    },{
      plugin: require('/var/fleet_tracker/app/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://1f13bc6c4ae14fe5830aa683b4ba9425@sentry.io/1470234","environment":"production","enabled":true},
    }]
